import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from "react-router-dom";
import throttle from 'lodash.throttle';
import NavBar from "./components/modules/NavBar";
import Footer from "./components/modules/Footer";
import { GlobalStyles, CarouselGlobalStyles } from "@mbank-design/design-system/styles";
import CookiesInformation from "./components/parts/CookiesInformation";
import "./App.scss";
import "./font.scss";

import { AuctionWebSocketContext, MobileFiltersContext, ToastContext, MobileSearchContext, FavouriteCarsContext } from "./scripts/commons/contexts";
import ToastComponent from "./components/modules/ToastComponent";
import { ButtonType } from "@mbank-design/design-system/enums";
import { mBankDark, mBank, mBankNext } from "@mbank-design/design-system/themes";

import { literalCreator } from "./literals/literals";
import { checkCampaignExpirationDate } from "./components/common/campaign-data-setter";
import FastButtons from "./components/modules/FastButtons";
import { Media } from "@mbank-design/design-system/components";
import { getMultipleVariantsInfo, getOffersFiltersData } from "./services";
import { LoadingSpinner } from "./components/parts/LoadingSpinner";
import SiteMap from "./components/pages/SiteMapPage";
import ExitPopup from "./components/modules/ExitPopup";
import { endpointGuard } from "./scripts/commons/guard/endpoint-guard";
import { HandShakeMass, HandThumbUpMass, SpeechBubbleContactMass } from "@mbank-design/design-system/illustrations";
import { imitationClickForCloseAllTooltips } from "./scripts/commons/common";
import LiveChatWidget from "./components/modules/LiveChatWidget";
import uuid from "react-uuid";
import SearchMenu from "./components/modules/SearchMenu";
import MobileMainPageSearch from "./components/modules/MobileMainPageSearch";
import ScrollToTop from "./components/modules/ScrollToTop/ScrollToTop";
import { REDIRECTS } from "./constants";
import { DesignSystemThemeProvider } from "@mbank-design/design-system/context/index.js";
import LandingPageHyundai from "./components/pages/LandingPageHyundai/index.js";
import LandingPageTeslaY from "./components/pages/LandingPageTeslaY";
import LandingPageAfi from "./components/pages/LandingPageAfi/index.js";
import LandingPageBMW from "./components/pages/LandingPageBMW/index.js";
import LandingPageKonkurs from "./components/pages/LandingPageKonkurs/index.js";
import LandingPageTargi from "./components/pages/LandingPageTargi/index.js";
import LandingPageTaniejZmAuto from "./components/pages/LandingPageTaniejZmAuto/index.js";
import LandingPageKartaPaliwowa from "./components/pages/LandingPageKartaPaliwowa/index.js";
import ScriptExecutor from "./components/common/ScriptExecutor.js";

const LandingPageQ7 = lazy(() => import("./components/pages/LandingPageQ7"));
const LandingPageTesla3 = lazy(() => import("./components/pages/LandingPageTesla3"));
const MyElectricCarPage = lazy(() => import("./components/pages/MyElectricCarPage"));
const DeactivateAlert = lazy(() => import("./components/pages/DeactivateAlert"));
const ActivateAlert = lazy(() => import("./components/pages/ActivateAlert"));
const AlertPage = lazy(() => import("./components/pages/AlertPage"));
const AcceptRulesPage = lazy(() => import("./components/pages/AcceptRulesPage"));
const ContactPage = lazy(() => import("./components/pages/ContactPage.js"));
const CertificatePage = lazy(() => import("./components/pages/CertificatePage"));
const LeasingNoWorriesPage = lazy(() => import("./components/pages/LeasingNoWorriesPage"));
const MainPage = lazy(() => import("./components/pages/MainPage"));
const ListPage = lazy(() => import("./components/pages/ListPage"));
const DetailsPage = lazy(() => import("./components/pages/DetailsPage"));
const Page404 = lazy(() => import("./components/pages/Page404"));
const FavoritePage = lazy(() => import("./components/pages/FavoritePage"));
const LoginPage = lazy(() => import("./components/pages/LoginPage"));
const FAQ = lazy(() => import("./components/pages/FAQ"));
const PasswordRecoveryPage = lazy(() => import("./components/pages/PasswordRecoveryPage"));
const RegistrationPage = lazy(() =>
  import("./components/pages/RegistrationPage")
);
const ConfirmationPage = lazy(() =>
  import("./components/pages/ConfirmationPage")
);
const SettingsPage = lazy(() => import("./components/pages/SettingsPage"));
const InSaleSoonPage = lazy(() => import("./components/pages/InSaleSoonPage"));
const CurentlyBiddingPage = lazy(() =>
  import("./components/pages/CurentlyBiddingPage")
);
const WonAuctionsPage = lazy(() =>
  import("./components/pages/WonAuctionsPage")
);
const ContactForm = lazy(() => import("./components/pages/ContactForm"));
const ContactFormSelfFinancing = lazy(() =>
  import("./components/pages/ContactFormSelfFinancing")
);
const FinancingPage = lazy(() => import("./components/pages/FinancingPage"));
const ProductLinePage = lazy(() =>
  import("./components/pages/ProductLinePage")
);
const RulesPage = lazy(() => import("./components/pages/RulesPage"));
const AboutPage = lazy(() => import("./components/pages/AboutPage"));
const ElectrificationOfTheFleet = lazy(()=>import("./components/pages/ElectricCampaignLp"));

const specialLandingPages = ["/lp-q7", "/lp-tesla-3", "/lp-hyundai", "/lp-tesla-y", "/lp-afi", "/lp-bmw", "/targi", "/konkurs"];
class App extends Component {
  constructor() {
    super();
    this.setShowNavAndFooter = this.setShowNavAndFooter.bind(this);
  }

  isSpecialLandingPage = specialLandingPages.includes(window.location.pathname.toLowerCase())
  
  componentDidMount() {
    literalCreator.getLiterals().then(() => {
      this.setState({ literals: literalCreator.data });
    });
    this.addPasswordToTestVersion();
    Promise.all([
      getOffersFiltersData().then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          this.setState({
            avalibleMakesAndModels: response.data.MakesAndModels,
            avalibleBodyTypes: response.data.BodyTypes,
            offersFiltersDataFromApi: response.data
          });
          return response.data;
        } else {
          endpointGuard(response.data.ErrorCode);
          return null;
        }
      }),
      this.getVehiclesMultipleVariants().then((res) => {
        this.setState({multipleVariantsInfo: res.data});
        return res.data
      })
    ]).then((finallRes) => {
      let offersFiltersDataFromApi = finallRes[0];
      let multipleVariantsInfo = finallRes[1];
      this.setState({ listLinks: this.generateListLinks(offersFiltersDataFromApi, multipleVariantsInfo) });
    });
    checkCampaignExpirationDate();
    window.addEventListener("scroll", throttle(this.windowOnScrolling, 1000));

      let favoriteCarList = JSON.parse(localStorage.getItem("favoriteCar"));
      if(!favoriteCarList){
        favoriteCarList=[]
      }
      this.updateFavouriteCarsList(favoriteCarList)
  }


  setShowNavAndFooter(show){
    this.setState({ ...this.state, showNavAndFooter: show });
  }

  addPasswordToTestVersion() {
    // dummy pass to test
    if (
      !sessionStorage.getItem("test-open") &&
      process.env.NODE_ENV === "production" &&
      window.location.href.includes("as-mleasing-mauto-test")
    ) {
      if (window.prompt("Podaj hasło do witryny testowej") !== "testmAuto") {
        this.addPasswordToTestVersion();
        sessionStorage.setItem("test-open", false);
      } else {
        sessionStorage.setItem("test-open", true);
      }
    }
  }

  changeDocumentBodyOverflow(hideOverflow) {
    let root = document.getElementById('root');
    if(root) {
      root.style.overflowY = hideOverflow ? "hidden" : "unset";
    }
  }

  toggleMobileFilters = () => {
    this.changeDocumentBodyOverflow(!this.state.mobileFilters.isMobileFiltersOpen);
    this.setState({
      mobileFilters: {
        ...this.state.mobileFilters,
        isMobileFiltersOpen: !this.state.mobileFilters.isMobileFiltersOpen,
      },
    });
  }

  toggleMobileSearch = () => {
    this.setState({
      mobileSearch: {
      ...this.state.mobileSearch,
      isMobileSearchOpen: !this.state.mobileSearch.isMobileSearchOpen,
    },
    });
  };

  toggleToast = async () => {
    await this.setState({
      toastState: {
        ...this.state.toastState,
        isOpen: !this.state.toastState.isOpen,
      },
    });
    return this.state.toastState.isOpen;
  };

  changeToastState = async (name, value) => {
    await this.setState({
      toastState: {
        ...this.state.toastState,
        [name]: value,
      },
    });
  };

  returnToastState = (name) => {
    return this.state.toastState[name];
  };


  updateFavouriteCarsList= (carsList) => {
    this.setState({
      favouriteCars: {
      ...this.state.favouriteCars,
      favourireCarsList: carsList,
    }});
  }

  state = {
    mobileSearch:{
      isMobileSearchOpen: false,
      toggleMobileSearch: this.toggleMobileSearch,
    },
    mobileFilters: {
      isMobileFiltersOpen: false,
      toggleMobileFilters: this.toggleMobileFilters,
    },
    toastState: {
      isOpen: false,
      color: "#0065b1",
      message: "",
      buttonText: "",
      toastIcon: "",
      wasReturned: false,
      toggleToast: this.toggleToast,
      returnToastState: this.returnToastState,
      changeToastState: this.changeToastState,
    },
    favouriteCars:{
      favourireCarsList: [],
      updateFavouriteCarsList: this.updateFavouriteCarsList,
    },
    themeDark: false,
    multipleVariantsInfo: null,
    isLogged: !!localStorage.getItem("access_token_m_Auto"),
    auctionWebSockets: {
      auctionWebSockets: [],
      biddingHubProxy: null,
      informAboutBid: 0,
    },
    avalibleMakesAndModels: null,
    avalibleBodyTypes: [],
    showNavAndFooter: true,
    isSearchMenuOpen:false
  };

  setDarkTheme(isDark) {
    this.setState({ themeDark: isDark });
  }

  setIsLogged(isLogged) {
    this.setState({ isLogged: isLogged });
  }
  async getVehiclesMultipleVariants() {
    return await getMultipleVariantsInfo().then((res) => {
      return res;
    });
  }

  
  getOffersFiltersDataFromApi() {
    getOffersFiltersData().then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        this.setState({
          avalibleMakesAndModels: response.data.MakesAndModels,
          avalibleBodyTypes: response.data.BodyTypes,
          offersFiltersDataFromApi: response.data
        });
      } else {
        endpointGuard(response.data.ErrorCode);
      }
    });
  }

  toggleSearchMenuState = () => {
    this.setState({isSearchMenuOpen:!this.state.isSearchMenuOpen});
    localStorage.removeItem("tempVehicle");
  }

  generateListLinks(offersFiltersDataFromApi, multipleVariantsInfo) {
    let links = [
      "/lista-pojazdow",
      "/samochody-poleasingowe",
      "/nowe-samochody",
      "/aukcje-samochodowe",
      "/samochody-elektryczne",
      "/promocje",
      "/sedan",
      "/hatchback",
      "/kombi",
      "/suv",
      "/bus",
      "/van",
    ];

    const allBrandLinks = offersFiltersDataFromApi?.AvailableAlertMakes.map(make => '/marki/' + make.toLowerCase().replace(/ /g,'-'));
    if(allBrandLinks) { 
      links.push(...allBrandLinks);
    }

    if (
      multipleVariantsInfo &&
      multipleVariantsInfo.length
    ) {
      for (let item of multipleVariantsInfo) {
        links.push(`/${item.Make.toLowerCase()}-${item.Model.toLowerCase()}`);
      }
    }

    return links;
  }

  windowOnScrolling() {
    imitationClickForCloseAllTooltips();
  }

  render() {
    const listLinks = this.generateListLinks();

    const allVehicleMakesLinks = this.state.offersFiltersDataFromApi?.MakesAndModels.map(vehicle => {
      return {
          name: vehicle.Make.Name.toUpperCase(), link: '/marki/' + vehicle.Make.Name.toLowerCase().replace(/ /g,'-')
        }
    });

    return (
      <div className="App">
        <DesignSystemThemeProvider
          theme={this.state.themeDark ? mBankDark : mBankNext}
          >
          <GlobalStyles/>
          <CarouselGlobalStyles/>
          <FavouriteCarsContext.Provider value={this.state.favouriteCars}>
          <MobileSearchContext.Provider value={this.state.mobileSearch}>
              <MobileFiltersContext.Provider value={this.state.mobileFilters}>
                <ToastContext.Provider value={this.state.toastState}>
                  <AuctionWebSocketContext.Provider
                    value={this.state.auctionWebSockets}
                    >
                      {this.state.toastState.isOpen && (
                        <ToastComponent
                        toggleToast={this.toggleToast}
                          message={this.state.toastState.message}
                          buttonText={this.state.toastState.buttonText}
                          toastIcon={this.state.toastState.toastIcon}
                          color={this.state.toastState.color}
                          />
                        )}
                      <Router>
                        <ScriptExecutor/>  
                        {/* <ExitPopup/> */}
                        <ScrollToTop/>
                        {this.state.isSearchMenuOpen &&
                        <SearchMenu
                          toggleSearchMenuState={this.toggleSearchMenuState}
                          availableMakesAndModels={this.state.avalibleMakesAndModels}
                          avalibleBodyTypes={this.state.avalibleBodyTypes}
                          literals={this.state.literals}
                        />
                        }
                        {this.state.showNavAndFooter && (
                          <NavBar
                            vehicleBrandLinks={allVehicleMakesLinks}
                            offersFiltersDataFromApi={this.state.offersFiltersDataFromApi}
                            isLogged={this.state.isLogged}
                            setIsLogged={(v) => this.setIsLogged(v)}
                            toggleSearchMenuState={this.toggleSearchMenuState}
                          />
                        )}
                        <main className="main-site-container">
                          <Suspense
                            fallback={
                              <div>
                                <LoadingSpinner/>
                              </div>
                            }
                          >
                            <Switch>
                            <Route
                              path={[
                                "/samochody-poleasingowe/:id",
                                "/afterLease/:id",
                              ]}
                            >
                              <DetailsPage
                                setDarkMode={this.state.themeDark}
                                setDarkTheme={(x) => this.setDarkTheme(x)}
                                avalibleMakesAndModels={this.state.avalibleMakesAndModels}
                                avalibleBodyTypes={this.state.avalibleBodyTypes}
                              />
                            </Route>
                            <Route path={["/nowe-samochody/:id", "/new/:id"]}>
                              <DetailsPage
                                setDarkMode={this.state.themeDark}
                                setDarkTheme={(x) => this.setDarkTheme(x)}
                                avalibleMakesAndModels={this.state.avalibleMakesAndModels}
                                avalibleBodyTypes={this.state.avalibleBodyTypes}
                              />
                            </Route>
                            <Route
                              path={["/aukcje-samochodowe/:id", "/auction/:id"]}
                            >
                              <DetailsPage
                                setDarkMode={this.state.themeDark}
                                setDarkTheme={(x) => this.setDarkTheme(x)}
                                avalibleMakesAndModels={this.state.avalibleMakesAndModels}
                                avalibleBodyTypes={this.state.avalibleBodyTypes}
                              />
                            </Route>
                            <Route path="/formularz-kontaktowy">
                              <ContactForm
                                literals={this.state.literals}
                                setDarkTheme={(x) => this.setDarkTheme(x)}
                              />
                            </Route>
                            <Route path="/lp-q7">
                              <LandingPageQ7
                                literals={this.state.literals}
                              />
                            </Route>
                            <Route path="/lp-tesla-3">
                              <LandingPageTesla3
                                literals={this.state.literals}
                              />
                            </Route>
                            <Route path="/targi">
                              <LandingPageTargi
                                literals={this.state.literals}
                              />
                            </Route>
                            <Route path="/lp-tesla-y">
                            <LandingPageTeslaY
                              downloadSiteRules={() => this.downloadSiteRules()}
                              clickAnchorWithRegulations={(res) =>
                                this.clickAnchorWithRegulations(res)
                              }
                            />
                          </Route>
                          <Route path="/lp-afi">
                            <LandingPageAfi
                              downloadSiteRules={() => this.downloadSiteRules()}
                              clickAnchorWithRegulations={(res) =>
                                this.clickAnchorWithRegulations(res)
                              }
                            />
                          </Route>
                          <Route path="/lp-hyundai">
                            <LandingPageHyundai
                              downloadSiteRules={() => this.downloadSiteRules()}
                              clickAnchorWithRegulations={(res) =>
                                this.clickAnchorWithRegulations(res)
                              }
                            />
                          </Route>
                          <Route path="/lp-bmw">
                            <LandingPageBMW
                              downloadSiteRules={() => this.downloadSiteRules()}
                              clickAnchorWithRegulations={(res) =>
                                this.clickAnchorWithRegulations(res)
                              }
                            />
                          </Route>
                          <Route path="/lp-taniej-z-mAuto">
                            <LandingPageTaniejZmAuto/>
                          </Route>
                          <Route path="/lp-2000-na-paliwo">
                            <LandingPageKartaPaliwowa/>
                          </Route>
                          <Route path="/konkurs">
                              <LandingPageKonkurs
                                literals={this.state.literals}
                              />
                            </Route>
                          <Route path="/elektryfikacja-floty">
                            <ElectrificationOfTheFleet
                              literals={this.state.literals}
                            />
                          </Route>
                            <Route path={this.state.listLinks}>
                              {this.state.offersFiltersDataFromApi &&
                                this.state.multipleVariantsInfo &&
                                this.state.avalibleMakesAndModels && (
                                  <ListPage
                                    offersFiltersDataFromApi={this.state.offersFiltersDataFromApi}
                                    avalibleMakesAndModels={this.state.avalibleMakesAndModels}
                                    multipleVariantsInfo={
                                      this.state.multipleVariantsInfo
                                    }
                                  />
                                )}
                          </Route>
                              <Route path="/ulubione">
                                <FavoritePage offersFiltersDataFromApi={this.state.offersFiltersDataFromApi} />
                              </Route>
                              <Route path="/faq">
                                <FAQ />
                              </Route>
                              <Route exact path="/">
                                {this.state.avalibleMakesAndModels && this.state.avalibleBodyTypes && this.state.offersFiltersDataFromApi &&
                                  <MainPage
                                    avalibleMakesAndModels={this.state.avalibleMakesAndModels}
                                    avalibleBodyTypes={this.state.avalibleBodyTypes}
                                    offersFiltersDataFromApi={this.state.offersFiltersDataFromApi} />
                                }
                              </Route>
                              <Route path="/login">
                                <LoginPage setIsLogged={(v) => this.setIsLogged(v)} />
                              </Route>
                              <Route path="/rejestracja">
                                <RegistrationPage />
                              </Route>
                              <Route path="/przypomnienie-hasla">
                                <PasswordRecoveryPage />
                              </Route>
                              <Route path="/oferta">
                                <ProductLinePage />
                              </Route>
                              <Route path="/kontakt">
                                <ContactPage literals={this.state.literals} />
                              </Route>
                              <Route exact path="/alert">
                                {this.state.offersFiltersDataFromApi && (
                                  <AlertPage
                                    offersFiltersDataFromApi={this.state.offersFiltersDataFromApi}
                                    changeDocumentBodyOverflow={(hideOverflow) => this.changeDocumentBodyOverflow(hideOverflow)} />
                                )}
                              </Route>
                              <Route path="/alert/dezaktywacja=:id">
                                <DeactivateAlert />
                              </Route>
                              <Route path="/alert/aktywacja=:id">
                                <ActivateAlert />
                              </Route>
                              <Route path="/finansowanie">
                                <FinancingPage />
                              </Route>
                              <Route path="/zaakceptuj-regulamin">
                                <AcceptRulesPage
                                  auctionRules={{
                                    base64: this.state.auctionRulesBase,
                                    name: this.state.auctionRulesName
                                  }}
                                />
                              </Route>
                              <Route path="/pomyslnie-zarejestrowano">
                                <ConfirmationPage
                                  data={{
                                    pageTitle: "Dziękujemy za rejestrację!",
                                    subTitle: "",
                                    description:
                                      "Teraz kliknij w link aktywacyjny, który wysłaliśmy do Ciebie e-mailem.",
                                    buttonType: ButtonType.SECONDARY,
                                    buttonText: "powrót do aukcji",
                                    redirectTo: "/lista-pojazdow",
                                    illustrationName: HandThumbUpMass
                                  }}
                                />
                              </Route>
                              <Route path="/aktywuj-konto">
                                <ConfirmationPage
                                  data={{
                                    pageTitle:
                                      "Dziękujemy za potwierdzenie adresu e-mail.",
                                    subTitle: "",
                                    description:
                                      "Teraz sprawdzimy Twoje dane i aktywujemy konto. Otrzymasz od nas e-mail z informacją, że Twoje konto już działa.",
                                    buttonType: ButtonType.SECONDARY,
                                    buttonText: "powrót do aukcji",
                                    redirectTo: "/lista-pojazdow",
                                    illustrationName: HandThumbUpMass,
                                    additionalAction: "confirmActivation"
                                  }}
                                />
                              </Route>
                              <Route path="/wyslano-pomyslnie">
                                <ConfirmationPage
                                  data={{
                                    pageTitle: "dziękujemy",
                                    subTitle: "Twoje zapytanie zostało wysłane",
                                    description:
                                      "nasz doradca skontaktuje się z Tobą w przeciągu 4 godzin",
                                    buttonType: ButtonType.SECONDARY,
                                  buttonText: "powrót do listy pojazdów",
                                  redirectTo: "/lista-pojazdow",
                                  illustrationName: HandThumbUpMass,
                                }}
                              />
                            </Route>
                            <Route path="/przeslano-numer">
                              <ConfirmationPage
                                data={{
                                  pageTitle: "",
                                  subTitle: "dziękujemy i do usłyszenia",
                                  description:
                                    "wkrótce do Ciebie oddzwonimy - odbierz od nas telefon",
                                  buttonType: ButtonType.SECONDARY,
                                  buttonText: "przejdź do listy pojazdów",
                                  redirectTo: "/lista-pojazdow",
                                  illustrationName: SpeechBubbleContactMass,
                                }}
                              />
                            </Route>
                            <Route path="/aktywacja-zakonczona-sukcesem">
                              <ConfirmationPage
                                data={{
                                  pageTitle: "konto jest aktywne",
                                  description:
                                    "Możesz od teraz uczestniczyć w licytacjach mAuto, dodawać do ulubionych oraz przeglądać nadchodzące aukcje.",
                                  buttonType: ButtonType.PRIMARY,
                                  buttonText: "zaloguj się",
                                  redirectTo: "/login",
                                  illustrationName: HandShakeMass,
                                }}
                              />
                            </Route>
                            <Route path="/wkrotce-w-sprzedazy">
                              <InSaleSoonPage/>
                            </Route>
                            <Route path="/licytowanie">
                              <CurentlyBiddingPage/>
                            </Route>
                            <Route path="/wygrane-aukcje">
                              <WonAuctionsPage/>
                            </Route>
                            <Route path="/ustawienia">
                              <SettingsPage/>
                            </Route>
                            <Route path="/formularz-samofinansowanie">
                              <ContactFormSelfFinancing/>
                            </Route>
                            <Route path="/moj-elektryk">
                              <MyElectricCarPage literals={this.state.literals} />
                            </Route>
                            <Route path="/leasing-BEZ-OBAW">
                              <LeasingNoWorriesPage />
                            </Route>
                            <Route path="/certyfikat-mAuto">
                              <CertificatePage literals={this.state.literals}/>
                            </Route>
                            <Route path="/regulamin-promocji-majowka-z-mAuto">
                              <RulesPage/>
                            </Route>
                            <Route path="/o-nas">
                              <AboutPage literals={this.state.literals}/>
                            </Route>
                            <Route path="/bmw">
                            <Redirect
                              to={'/marki/bmw?utm_source=autopay&utm_medium=sms&utm_campaign=bmw&utm_id=bmw_seria3&utm_term=04.2024'}
                            ></Redirect>
                            </Route>
                              {REDIRECTS.map(link =>
                                <Route path={link.from} key={uuid()}>
                                  <Redirect
                                    to={link.to}
                                  ></Redirect>
                                </Route>
                              )}
                            <Route path="/sitemap.xml">
                              <SiteMap/>
                            </Route>
                            <Route path="*" status={404}>
                              <Page404/>
                            </Route>
                          </Switch>
                          </Suspense>
                        </main>
                        {this.state.showNavAndFooter && (
                          <>
                            <Media.DesktopOnly>
                              {!this.isSpecialLandingPage && <FastButtons />}
                            </Media.DesktopOnly>
                            <Footer />
                          </>
                        )}
                          <>
                            {this.state.mobileSearch.isMobileSearchOpen && (
                              <MobileMainPageSearch
                                literals={this.state.literals}
                                avalibleMakesAndModels={this.state.avalibleMakesAndModels}
                                closeMobileSearch={() => this.state.mobileSearch.toggleMobileSearch()}
                              />
                            )}
                          </>
                        {!this.isSpecialLandingPage && <LiveChatWidget />}
                        <CookiesInformation/>
                      </Router>
                    </AuctionWebSocketContext.Provider>
                  </ToastContext.Provider>
                </MobileFiltersContext.Provider>
            </MobileSearchContext.Provider>
            </FavouriteCarsContext.Provider>
          </DesignSystemThemeProvider>
      </div>
    );
  }
}

export default App;
