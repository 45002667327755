import React from "react";
import "./index.scss";
import carSvg from "./images/car.svg";
import carPng from "./images/car.png";
import screenSvg from "./images/screen.svg";
import wrenchSvg from "./images/wrench.svg";
import rewardPng from "./images/reward.png";
import penSvg from "./images/pen.svg";
import houseSvg from "./images/house.svg";
import shieldSvg from "./images/shield.svg";
import hourglassSvg from "./images/hourglass.svg";
import promoRegulations from "./Regulamin-2000-zl-na-paliwo.pdf";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const LandingPageTaniejZmAuto = () => {
  return (
    <div className="lp-karta-paliwowa">
      <Helmet>
        <title>Taniej z mAuto</title>
        <meta name="description" content="" />
      </Helmet>

      <header className="header">
        <div class="container">
          <div class="border header__border"></div>
          <h1 class="letter-spacing header__title">
            Nowe auta
            <br /> z&nbsp;<span class="text-orange">kartą paliwową</span>
            <br /> o&nbsp;wartości <span class="text-orange">2000&nbsp;zł !</span>
          </h1>
          <p class="letter-spacing header__text">dla klientów firmowych mBanku</p>
          <a
            class="btn btn--lg"
            href="https://mauto.pl/lista-pojazdow?promotions=12&isCompany=true&financialMethod=all&selectedSortingValue=0"
            title="kliknij, aby kupić auto"
          >
            wybierz auto
          </a>
        </div>
      </header>

      <main>
        <section class="section benefits">
          <div class="container">
            <h2 class="text-center letter-spacing title benefits__title">
              Na tym korzyści dla Ciebie i&nbsp;Twojej firmy się nie kończą!
            </h2>
            <p class="text-center letter-spacing benefits__subtitle">Zobacz, co&nbsp;jeszcze zyskujesz:</p>
            <ul class="benefits__items">
              <li class="benefits__item">
                <div class="box box--1">
                  <h3 class="text-center letter-spacing box__title">bezpieczny zakup</h3>
                  <img class="box__img" src={shieldSvg} alt="" />
                  <p class="text-center letter-spacing box__text">
                    auta zgodne z&nbsp;polskimi standardami i&nbsp;wymogami
                  </p>
                </div>
              </li>
              <li class="benefits__item">
                <div class="box box--2">
                  <h3 class="text-center letter-spacing box__title">szybki proces</h3>
                  <img class="box__img" src={hourglassSvg} alt="" />
                  <p class="text-center letter-spacing box__text">
                    auto odbierz w&nbsp;ciągu kilku dni od&nbsp;podpisania umowy
                  </p>
                </div>
              </li>
              <li class="benefits__item">
                <div class="box box--3">
                  <h3 class="text-center letter-spacing box__title">twój komfort</h3>
                  <img class="box__img" src={houseSvg} alt="" />
                  <p class="text-center letter-spacing box__text">auto dostarczymy za&nbsp;darmo pod wskazany adres</p>
                </div>
              </li>
            </ul>
            <div class="text-center benefits__btn">
              <a
                class="btn"
                href="https://mauto.pl/lista-pojazdow?promotions=12&isCompany=true&financialMethod=all&selectedSortingValue=0"
                title="kliknij, aby kupić auto"
              >
                wybierz auto
              </a>
            </div>
          </div>
        </section>

        <section class="section offer">
          <div class="container">
            <h2 class="text-center letter-spacing title offer__title">
              A zatem nie zwlekaj i&nbsp;skorzystaj już dziś!
            </h2>
            <div class="offer__content">
              <img class="offer__img" src={penSvg} alt="" />
              <p class="letter-spacing offer__text">
                wystarczy, że&nbsp;podpiszesz umowę finansowania oraz oświadczenie o&nbsp;przystąpieniu do&nbsp;promocji
              </p>
            </div>
            <div class="text-center">
              <a class="btn" href="https://mauto.pl/formularz-kontaktowy" title="kliknij, aby zapytać o ofertę">
                zapytaj o ofertę
              </a>
            </div>
          </div>
        </section>

        <section class="section car">
          <div class="container">
            <div class="border car__border"></div>
            <h2 class="text-center letter-spacing title car__title">Wybierz idealny nowy samochód dla siebie</h2>
            <p class="text-center letter-spacing car__text">
              i&nbsp;zgarnij karte paliwową o&nbsp;wartości{" "}
              <span class="text-red font-weight-semibold">2000&nbsp;zł</span>
            </p>
            <img class="car__img" src={carPng} alt="auto" />
            <div class="text-center">
              <a
                class="btn"
                href="https://mauto.pl/lista-pojazdow?promotions=12&isCompany=true&financialMethod=all&selectedSortingValue=0"
                title="kliknij, aby kupić auto"
              >
                wybierz auto
              </a>
            </div>
          </div>
        </section>

        <section class="section reward">
          <div class="container">
            <div class="reward__content">
              <div class="reward__copy">
                <h2 class="letter-spacing title reward__title">
                  mAuto nagrodzone w&nbsp;13. Fleet Derby 2024 w&nbsp;kategorii „
                  <span class="text-red">Usługa finansowa dla MŚP</span>”
                </h2>
                <p class="letter-spacing reward__text">
                  Głosy Internautów potwierdziły, iż&nbsp;jesteśmy sprawdzoną marką, którą&nbsp;na&nbsp;rynku wyróżniają
                  sprawne procedury i&nbsp;doświadczeni doradcy.
                </p>
                <p class="letter-spacing reward__text reward__text--last">Dziękujemy za&nbsp;zaufanie!</p>
              </div>
              <img class="reward__img" src={rewardPng} alt="Najlepszy Produkt | Usługa" />
            </div>
          </div>
        </section>
      </main>

      <footer class="footer">
        <div class="container footer__container">
          <ul class="footer__list">
            <li class="footer__item">
              <a
                class="letter-spacing footer__link"
                href={promoRegulations}
                target="_blank"
              >
                Regulamin promocji
              </a>
            </li>
          </ul>
          <p class="letter-spacing footer__text">
            Informujemy, że poprzez kliknięcie w aktywne treści zawarte w powyższej informacji (tzw. „hiperłącza”)
            wyrażasz zgodę na gromadzenie i przetwarzanie przez mLeasing sp. z o.o. z siedzibą w Warszawie, danych w
            zakresie geolokalizacji, modelu urządzenia lub rodzaju przeglądarki internetowej w zestawieniu z informacją,
            które z aktywnych treści zawarte w informacji i w jakiej dacie, wywołały przekierowanie lub Twoją reakcję
            oraz z jakiego źródła (portalu społecznościowego) nastąpiło przekierowanie, w tym na profilowanie z
            wykorzystaniem powyższych informacji, w celu analiz i doskonalenia przesyłanych materiałów informacyjnych i
            promocyjnych, a także rozwijania przez nas kampanii marketingowych. Podstawą przetwarzania danych osobowych
            w celach marketingowych oraz doskonalenia prezentowanych materiałów jest Twoja dobrowolna zgoda. Masz prawo
            dostępu do swoich danych osobowych i ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do
            przenoszenia danych, a także prawo wniesienia sprzeciwu wobec przetwarzania danych z przyczyn związanych ze
            szczególną sytuacją, jak również, prawo do cofnięcia zgody w dowolnym momencie. Więcej informacji o
            pozostałych celach przetwarzania danych i przysługujących prawach znajduje się na <a href="https://mleasing.pl/rodo/">mleasing.pl/rodo</a> mAuto
            jest serwisem internetowym należącym do mLeasing Sp. z o.o., stroną umowy będzie mLeasing Sp. z o.o.
            Informacja nie stanowi oferty w rozumieniu przepisów Kodeksu cywilnego. Ma charakter jedynie poglądowy i
            pomocniczy w przygotowaniu właściwej umowy. Umowa wymaga formy pisemnej pod rygorem nieważności i może być
            zawarta nie wcześniej niż po podjęciu przez mLeasing Sp. z o.o. pozytywnej decyzji o finansowaniu.
            <a href="https://mleasing.pl/pdf/promocje/Regulamin-2000-zl-na-paliwo.pdf">Regulamin promocji „2000 zł na paliwo”</a> dostępny na mLeasing.pl
          </p>
        </div>
      </footer>
      <script src="./scripts/vendor.bundle.js"></script>
      <script src="./scripts/main.bundle.js"></script>
    </div>
  );
};

export default LandingPageTaniejZmAuto;
